import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import PatientInfo from "./PatientInfo";
import NewPatient from "./NewPatient";
import PatientDataService from "../services/PatientDataService";
import ClinicDataService from "../services/ClinicDataService";
import { useAppContext } from "../services/authContext";

function Patients(props) {
  const [newPat, setNewPat] = useState(false);
  const { clinicId } = useAppContext();
  const [patientList, setPatientList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [totalElement, setTotalElement] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    populateClinicPatients(page, rowsPerPage);
    populateClinicDoctors();
  }, []);

  function populateClinicPatients(pg, sz) {
    PatientDataService.findAllPatientsByClinic(clinicId, pg, sz).then(
      (response) => {
        setPatientList(response.data.content);
        setTotalElement(response.data.totalElements);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function populateClinicDoctors() {
    ClinicDataService.findDoctorsByClinicId(clinicId).then(
      (response) => {
        let tmpDocs = [];
        response.data.forEach((d) => tmpDocs.push({ ...d, checked: false }));
        setDoctorList(tmpDocs.slice());
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const changeDoc = (event) => {
    let tmpList = doctorList.slice();
    const index = tmpList.findIndex(
      (doc) => doc.id === parseInt(event.target.name)
    );
    let doc = tmpList.at(index);
    tmpList[index] = { ...doc, checked: event.target.checked };
    setDoctorList(tmpList.slice());
  };

  function openNewPat() {
    setNewPat(true);
  }

  function closeNewPat() {
    setNewPat(false);
  }

  return (
    <Box>
      {newPat ? (
        <NewPatient
          closeNewPat={closeNewPat}
          doctorList={doctorList}
          changeDoc={changeDoc}
          populateClinicDoctors={populateClinicDoctors}
          populateClinicPatients={populateClinicPatients}
        />
      ) : (
        <PatientInfo
          openNewPat={openNewPat}
          patientList={patientList}
          totalElement={totalElement}
          populateClinicDoctors={populateClinicDoctors}
          populateClinicPatients={populateClinicPatients}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPatientList={setPatientList}
        />
      )}
    </Box>
  );
}

export default Patients;
