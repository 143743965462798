import React, { useState, useEffect } from "react";
import AppDetails from "./AppDetails";
import AppList from "./AppList";

function ExistedApps(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [appDetails, setAppDetails] = useState({});
  const [patient, setPatient] = useState([]);
  const [comments, setComments] = useState("");
  const [reminders, setReminders] = useState([]);
  const [survey, setSurvey] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(new Map());
  const [room, setRoom] = useState("0");
  const [isApplist, setIsApplist] = useState(true);

  const changeAppShow = () => {
    if (!isApplist) {
      setIsApplist(!isApplist);
    }
  };

  const changeCalendarShow = () => {
    if (isApplist) {
      setIsApplist(!isApplist);
    }
  };

  function displayDetails() {
    setShowDetails(true);
  }

  function showAppList() {
    props.refreshAppList();
    setShowDetails(false);
  }

  const removeReminder = (index) => (event) => {
    // if (window.confirm("Do you really want to delete this reminder?")) {
    //   let tempReminders = [...reminders];
    //   tempReminders.splice(index, 1);
    //   setReminders([...tempReminders]);
    // }
    let tempReminders = [...reminders];
    tempReminders.splice(index, 1);
    setReminders([...tempReminders]);
  };

  return (
    <React.Fragment>
      {showDetails ? (
        <AppDetails
          setAppDetails={setAppDetails}
          showAppList={showAppList}
          appDetails={appDetails}
          patient={patient}
          comments={comments}
          reminders={reminders}
          setReminders={setReminders}
          survey={survey}
          questions={questions}
          answers={answers}
          setComments={setComments}
          removeReminder={removeReminder}
          room={room}
          setRoom={setRoom}
        />
      ) : (
        <AppList
          clinicCategories={props.clinicCategories}
          currentDay={props.currentDay}
          currentTabIndex={props.currentTabIndex}
          handleTabChange={props.handleTabChange}
          dayTabs={props.dayTabs}
          appointments={props.appointments}
          openNewApp={props.openNewApp}
          nextWeek={props.nextWeek}
          previousWeek={props.previousWeek}
          displayDetails={displayDetails}
          setAppDetails={setAppDetails}
          setRoom={setRoom}
          setPatient={setPatient}
          setComments={setComments}
          setReminders={setReminders}
          setSurvey={setSurvey}
          setAnswers={setAnswers}
          setQuestions={setQuestions}
          appList={props.appList}
          isApplist={isApplist}
          changeCalendarShow={changeCalendarShow}
          changeAppShow={changeAppShow}
        />
      )}
    </React.Fragment>
  );
}

export default ExistedApps;
