import React, { useState } from "react";
import DoctorList from "./DoctorList";
import DoctorDetail from "./DoctorDetail";
import moment from "moment";
import DoctorDataService from "../services/DoctorDataService";
import { useAppContext } from "../services/authContext";
import ClinicDataService from "../services/ClinicDataService";
import SurveyDataService from "../services/SurveyDataService";

function DoctorsInfo(props) {
  const { displayMessage, clinicId, displayAlert } = useAppContext();
  const [showDetails, setShowDetails] = useState(false);
  const [doctor, setDoctor] = useState({});
  const [patients, setPatients] = useState([]);
  const [availTimes, setAvailTimes] = useState([]);
  const [treatmentConfig, setTreatmentConfig] = useState([]);
  const [categories, setCategories] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [fullCategories, setFullCategories] = useState([]);
  const [categorieViewDataList, setCategorieViewDataList] = useState([]);
  const [clinicSurveys, setClinicSurveys] = useState([]);
  const [editDoctor, setEditDoctor] = useState({});
  const [docDob, setDocDob] = useState(null);
  const [docCardExp, setDocCardExp] = useState(null);
  const [apps, setApps] = useState([]);
  const [languages, setLanguages] = useState({
    fr: false,
    en: false,
    zh: false,
  });

  //
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function populatePatients(doctorId) {
    DoctorDataService.findPatients(doctorId).then(
      (response) => {
        setPatients(response.data);
      },
      (error) => console.log(error)
    );
  }

  const displayDetails = (doctorId) => (event) => {
    DoctorDataService.getDocInfo(doctorId).then(
      (response) => {
        populateDoctorCategories(doctorId);
        let tmpDoctor = {
          ...response.data,
          gender: response.data.gender === "1" ? "M" : "F",
        };
        setDoctor(tmpDoctor);
        setDocCardExp(
          tmpDoctor.mediCardExp ? Date.parse(tmpDoctor.mediCardExp) : new Date()
        );
        setDocDob(
          tmpDoctor.dateOfBirth ? Date.parse(tmpDoctor.dateOfBirth) : new Date()
        );
        setEditDoctor({
          ...tmpDoctor,
          enIntro: tmpDoctor.introduction
            ? JSON.parse(tmpDoctor.introduction).en
            : "",
          frIntro: tmpDoctor.introduction
            ? JSON.parse(tmpDoctor.introduction).fr
            : "",
        });
        if (response.data.language != null) {
          setLanguages({
            fr: response.data.language.includes("fr"),
            en: response.data.language.includes("en"),
            zh: response.data.language.includes("zh"),
          });
        }
        populatePatients(doctorId);
        populateDoctorAvailTime(doctorId);
        populateDoctorTreatment(clinicId, doctorId);
        populateDoctorSurveys(doctorId);
        populateClinicSurvey(clinicId);
        populateApps(doctorId, clinicId);
      },
      (error) => console.log(error)
    );
    setShowDetails(true);
  };

  function populateApps(doctorId, clinicId) {
    DoctorDataService.findAppsByDoctors(doctorId, clinicId).then(
      (response) => setApps(response.data.content),
      (error) => console.log(error)
    );
  }

  function populateClinicSurvey(clinicId) {
    SurveyDataService.findAllClinicSurveys(clinicId, "PUBLISH").then(
      (response) => setClinicSurveys(response.data),
      (error) => console.log(error)
    );
  }

  function populateDoctorCategories(doctorId) {
    DoctorDataService.findAllCategoriesOfDoctor(doctorId).then(
      (response) => {
        let tempCategories = [];
        let doctorCategoryIds = [];
        console.log("hasCategory", response.data);
        response.data.forEach((rawCategory) => {
          let description = JSON.parse(rawCategory.categoryDescription);
          tempCategories.push({
            categoryId: rawCategory.categoryId,
            name: description.en,
            allowNewPatient:
              rawCategory.allowNewPatient === "1"
                ? "Allow new patient"
                : rawCategory.allowNewPatient === "2"
                ? "Allow new patient if urgent case"
                : "Don't accept new patient",
            allowNewPatientId: rawCategory.allowNewPatient,
          });
          doctorCategoryIds.push(rawCategory.categoryId + "");
        });
        setCategories([...tempCategories]);
        //setSearchCat(tempCategories[0].id);
        ClinicDataService.findAllCategories().then(
          (response) => {
            setFullCategories(response.data);
            let categoryTree = generateCategoryTreeNew(
              response,
              doctorCategoryIds
            );
            setCategorieViewDataList(categoryTree);
          },
          (error) => console.log(error)
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function generateCategoryTreeNew(response, doctorCategoryIds) {
    let categoryObj = {
      id: "root",
      name: "categories",
    };
    let group = [];

    const layer1 = response.data.filter((item) => item.id.length === 2);
    const layer2 = response.data.filter((item) => item.id.length === 4);
    const layer3 = response.data.filter((item) => item.id.length === 6);

    layer1.forEach((item) => {
      //
      const foundSelectedFirstCategory = doctorCategoryIds.includes(item.id);
      //
      const description = JSON.parse(item.description);
      if (!foundSelectedFirstCategory) {
        group.push(
          item.isLeaf === "0"
            ? {
                id: item.id,
                name: description.en,
                isLeaf: item.isLeaf,
                children: [],
              }
            : {
                id: item.id,
                name: description.en,
                isLeaf: item.isLeaf,
              }
        );
      }
    });

    layer2.forEach((item) => {
      //
      const foundSelectedSecondCategory = doctorCategoryIds.includes(item.id);
      //
      const firstLayerElement = group.find(
        (firstElement) => firstElement.id === item.id.substring(0, 2)
      );
      let secondLayerList = firstLayerElement.children
        ? firstLayerElement.children
        : [];
      const hasSecondLayerElement = secondLayerList.find(
        (secondElement) => secondElement.id === item.id
      );
      if (!hasSecondLayerElement && !foundSelectedSecondCategory) {
        const description = JSON.parse(item.description);

        secondLayerList.push(
          item.isLeaf === "0"
            ? {
                id: item.id,
                name: description.en,
                isLeaf: item.isLeaf,
                children: [],
              }
            : {
                id: item.id,
                name: description.en,
                isLeaf: item.isLeaf,
              }
        );
      }
    });

    layer3.forEach((item) => {
      //
      const foundSelectedThirdCategory = doctorCategoryIds.includes(item.id);
      //
      const firstLayerElement = group.find(
        (firstElement) => firstElement.id === item.id.substring(0, 2)
      );
      const secondLayerList = firstLayerElement.children
        ? firstLayerElement.children
        : [];
      const secondLayerElement = secondLayerList.find(
        (secondElement) => secondElement.id === item.id.substring(0, 4)
      );
      let thirdLayerList = secondLayerElement.children
        ? secondLayerElement.children
        : [];
      const hasThirdLayerElement = thirdLayerList.find(
        (thirdElement) => thirdElement.id === item.id
      );
      if (!hasThirdLayerElement && !foundSelectedThirdCategory) {
        const description = JSON.parse(item.description);
        thirdLayerList.push({
          id: item.id,
          name: description.en,
          isLeaf: item.isLeaf,
        });
      }
    });
    categoryObj.children = group;
    return categoryObj;
  }

  function populateDoctorTreatment(clinicId, doctorId) {
    DoctorDataService.findDoctorTreatment(clinicId, doctorId).then(
      (response) => {
        const configsMap = new Map();
        response.data.forEach((config) => {
          if (configsMap.has(config.categoryId)) {
            configsMap.get(config.categoryId).push(config);
          } else {
            configsMap.set(config.categoryId, [config]);
          }
        });

        let list = Array.from(configsMap);
        list.sort((a, b) => {
          const aTemp = JSON.parse(a[1][0].categoryDescription).en;
          const bTemp = JSON.parse(b[1][0].categoryDescription).en;
          return aTemp.localeCompare(bTemp);
        });
        setTreatmentConfig(list);
      },
      (error) => console.log(error)
    );
  }

  function populateDoctorSurveys(doctorId) {
    DoctorDataService.findSurveys(doctorId).then(
      (response) => {
        const surveyMap = new Map();
        response.data.forEach((rowSurvey) => {
          if (surveyMap.has(rowSurvey.categoryId)) {
            surveyMap.get(rowSurvey.categoryId).push(rowSurvey);
          } else {
            surveyMap.set(rowSurvey.categoryId, [rowSurvey]);
          }
        });
        let list = Array.from(surveyMap);
        list.sort((a, b) => {
          const aTemp = JSON.parse(a[1][0].desc).en;
          const bTemp = JSON.parse(b[1][0].desc).en;
          return aTemp.localeCompare(bTemp);
        });
        setSurveys(list);
      },
      (error) => console.log(error)
    );
  }

  function populateDoctorAvailTime(doctorId) {
    DoctorDataService.findAllAvailableTime(doctorId).then(
      (response) => {
        if (response.data) {
          const availTimesMap = new Map();
          response.data.forEach((availTime) => {
            if (availTimesMap.has(availTime.categoryId)) {
              availTimesMap.get(availTime.categoryId).push(availTime);
            } else {
              availTimesMap.set(availTime.categoryId, [availTime]);
            }
          });
          let list = Array.from(availTimesMap);
          list.sort((a, b) => {
            const aTemp = JSON.parse(a[1][0].categoryDescription).en;
            const bTemp = JSON.parse(b[1][0].categoryDescription).en;
            return aTemp.localeCompare(bTemp);
          });
          setAvailTimes(list);
        }
      },
      (error) => console.log(error)
    );
  }

  function showDoctorList() {
    setShowDetails(false);
  }

  const deleteDoctor = (doctorId) => (event) => {
    if (window.confirm("Do you really want to delete this doctor?r")) {
      DoctorDataService.deleteDoctor(clinicId, doctorId).then(
        (response) => {
          props.populateClinicDoctors();
          displayMessage("The doctor has been deleted.");
        },
        (error) => console.log(error)
      );
    }
  };

  const changeDoctorInfo = (keyword) => (event) => {
    setEditDoctor({ ...editDoctor, [keyword]: event.target.value });
  };

  const changeLanguge = (event) => {
    setLanguages({ ...languages, [event.target.name]: event.target.checked });
  };

  function changeDocDob(date) {
    setDocDob(date);
    setEditDoctor({
      ...editDoctor,
      dateOfBirth: moment(date).format("YYYY-MM-DD"),
    });
  }

  function changeDocCardExp(date) {
    setDocCardExp(date);
    setEditDoctor({
      ...editDoctor,
      docCardExp: moment(date).format("YYYY-MM-DD"),
    });
  }

  const changePhoneType = (type) => (event) => {
    setEditDoctor({ ...editDoctor, contactPhoneType: type });
  };

  function updateDoctor() {
    let languageArray = [];
    if (languages.fr) languageArray.push("fr");
    if (languages.en) languageArray.push("en");
    if (languages.zh) languageArray.push("zh");
    DoctorDataService.updateDocInfo({
      ...editDoctor,
      gender: editDoctor.gender === "M" ? "1" : "2",
      introduction: JSON.stringify({
        en: editDoctor.enIntro,
        fr: editDoctor.frIntro,
      }),
      dateOfBirth: moment(docDob).format("YYYY-MM-DD"),
      mediCardExp: moment(docCardExp).format("YYYY-MM-DD"),
      language: languageArray.join(","),
    }).then(
      (response) => {
        displayMessage("The doctor has been successfully updated");
      },
      (error) => {
        displayAlert(
          "There is something wrong so that the doctor cannot be updated!"
        );
        console.log(error);
      }
    );
  }

  return (
    <React.Fragment>
      {showDetails ? (
        <DoctorDetail
          doctor={doctor}
          editDoctor={editDoctor}
          setDocDob={setDocDob}
          docDob={docDob}
          changeDocDob={changeDocDob}
          docCardExp={docCardExp}
          changeDocCardExp={changeDocCardExp}
          changeDoctorInfo={changeDoctorInfo}
          changePhoneType={changePhoneType}
          updateDoctor={updateDoctor}
          showDoctorList={showDoctorList}
          availTimes={availTimes}
          treatmentConfig={treatmentConfig}
          patients={patients}
          populatePatients={populatePatients}
          populateDoctorAvailTime={populateDoctorAvailTime}
          populateDoctorTreatment={populateDoctorTreatment}
          populateDoctorCategories={populateDoctorCategories}
          populateDoctorSurveys={populateDoctorSurveys}
          categories={categories}
          apps={apps}
          surveys={surveys}
          clinicSurveys={clinicSurveys}
          fullCategories={fullCategories}
          categorieViewDataList={categorieViewDataList}
          languages={languages}
          changeLanguge={changeLanguge}
        />
      ) : (
        <DoctorList
          doctorList={props.doctorList}
          openNewDoc={props.openNewDoc}
          displayDetails={displayDetails}
          deleteDoctor={deleteDoctor}
          setDoctorList={props.setDoctorList}
          populateClinicDoctors={props.populateClinicDoctors}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
    </React.Fragment>
  );
}

export default DoctorsInfo;
