import React, { useState } from "react";
import {
  makeStyles,
  IconButton,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Button,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { createMuiTheme } from "@material-ui/core/styles";
import { fade, ThemeProvider } from "@material-ui/core/styles";
import SearchBar from "./SearchBar";
import SurveyDataService from "../services/SurveyDataService";
import { useAppContext } from "../services/authContext";
import TablePagination from "@material-ui/core/TablePagination";
import "./PatienDetailStyle.css";
import orderBy from "lodash/orderBy";
import UpArrow from "@material-ui/icons/ArrowUpward";
import DownArrow from "@material-ui/icons/ArrowDownward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./ContentListStyle.css";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        border: "1px solid #DEE7F6",
        minWidth: 200,
      },
      text: {
        color: "#999999",
      },
      label: {
        justifyContent: "left",
        marginLeft: 15,
      },
      endIcon: {
        marginLeft: 85,
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #DEE7F6",
        top: 1.3,
      },
      input: {
        color: "#1A568E",
        textAlign: "left",
      },
    },
    MuiInput: {
      formControl: {
        border: 0,
      },
    },
    MuiTypography: {
      body1: {
        color: "#1A568E",
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: "#235FC1",
          "&:hover": {
            color: "#235FC1",
          },
        },
      },
    },
    MuiTableCell: {
      root: {},
      head: {
        color: "#777777",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  search: {
    display: "inline",
    position: "relative",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,

    height: "100%",
  },
  searchIcon: {
    display: "inline",
    height: "100%",
    width: "20%",
    padding: "10px 15px 10px 15px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #388EF2",
    borderRadius: "0px 5px 5px 0px",
    background: "#388EF2",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    width: "100%",
    minWidth: 300,
  },
  issueBtn: {
    background: "#388EF2",
    borderRadius: 5,
    font: "normal normal 600 1em/ 1em Montserrat",
    color: "#F5F8FC",
    textTransform: "capitalize",
  },
}));
function SurveyList(props) {
  const { displayMessage } = useAppContext();
  const [pg, setPg] = React.useState(0);
  const totalPageNumber = Math.ceil(props.totalElement / props.rowsPerPage);

  const [columnToSort, setColumnToSort] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const invertDirection = {
    asc: "desc",
    desc: "asc",
  };

  const handleSort = (columnName) => {
    if (columnToSort === columnName) {
      setSortDirection(invertDirection[sortDirection]);
    } else {
      setColumnToSort(columnName);
      setSortDirection(invertDirection["desc"]);
    }
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
    setPg(newPage);
    console.log("Page", newPage + 1, "of", totalPageNumber);
    props.populateSurveys(newPage, props.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
    props.populateSurveys(0, event.target.value);
  };

  const deleteSurvey = (surveyId) => (event) => {
    if (window.confirm("Do you really want to delete this survey?")) {
      SurveyDataService.disableSurvey(surveyId).then(
        (response) => {
          props.populateSurveys(0, 10);
          displayMessage("The survey has been successfully deleted.");
        },
        (error) => console.log(error)
      );
    }
  };

  const changeStatus = (surveyId, toStatus) => (event) => {
    SurveyDataService.changeSurveyStatus(surveyId, toStatus).then(
      (response) => {
        props.populateSurveys(props.page, props.rowsPerPage);
        displayMessage("The survey status has been successfully changed.");
      },
      (error) => console.log("Failed")
    );
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <SearchBar name="Survey" openNewIssue={props.openNewSurvey} />
        {/* <Grid container>
        <Grid item md={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Survey</InputLabel>
            <Select
              id="category"
              name="category"
              label="Category"
              variant="outlined"
              value={"00"}
            >
              <MenuItem key="1" value="1">
                Survey name
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="category-label">Search</InputLabel>
            <OutlinedInput
              id="searchBox"
              name="searchBox"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={6} align="right">
          <Button
            onClick={props.openNewSurvey}
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
          >
            New survey
          </Button>
        </Grid>
      </Grid> */}
        <Table style={{ marginTop: 20 }} className="survey-list-table">
          <TableHead
            style={{
              font: "normal normal 600 12px / 10px Montserrat",
              color: "#777777",
              backgroundColor: "#E2E7EF",
              boxShadow: "20px 20px 50px #EBEBEB7A",
            }}
          >
            <TableRow
              style={{
                font: "normal normal 600 12px / 10px Montserrat",
                color: "#777777",
              }}
            >
              <TableCell className="col-title small-col">
                <div
                  onClick={() => handleSort("id")}
                  className="calendar-table-header"
                >
                  <span>ID</span>
                  {columnToSort === "id" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title large-col">
                <div
                  onClick={() => handleSort("title")}
                  className="calendar-table-header"
                >
                  <span>Title</span>
                  {columnToSort === "title" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell className="col-title large-col">Creator</TableCell>
              <TableCell className="col-title medium-col">Last edit</TableCell>
              <TableCell className="col-title medium-col">
                <div
                  onClick={() => handleSort("status")}
                  className="calendar-table-header"
                >
                  <span>Status</span>
                  {columnToSort === "status" ? (
                    sortDirection === "asc" ? (
                      <UpArrow />
                    ) : (
                      <DownArrow />
                    )
                  ) : null}
                </div>
              </TableCell>
              <TableCell>Action</TableCell>
              <TableCell className="col-title action-col"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.surveyList.length > 0
              ? orderBy(props.surveyList, columnToSort, sortDirection).map(
                  (survey) => (
                    <TableRow key={survey.id}>
                      <TableCell
                        className="col-content small-col"
                        // style={{
                        //   font: "normal normal medium 12px / 10px Montserrat",
                        //   color: "#777777",
                        // }}
                      >
                        {survey.id}
                      </TableCell>
                      <TableCell
                        className="col-content large-col"
                        // style={{
                        //   font: "normal normal medium 12px / 10px Montserrat",
                        //   color: "#777777",
                        // }}
                      >
                        {survey.title}
                      </TableCell>
                      <TableCell
                        className="col-content large-col bold-font"
                        // style={{
                        //   font: "normal normal medium 12px / 10px Montserrat",
                        //   color: "#333333",
                        // }}
                      >
                        {survey.firstName +
                          " " +
                          survey.middleName +
                          " " +
                          survey.lastName}
                      </TableCell>
                      <TableCell
                        className="col-content medium-col"
                        // style={{
                        //   font: "normal normal medium 12px / 10px Montserrat",
                        //   color: "#777777",
                        // }}
                      >
                        {survey.updatedDate
                          ? survey.updatedDate
                              .substring(0, 16)
                              .replace("T", " ")
                          : ""}
                      </TableCell>
                      <TableCell
                        className="col-content medium-col"
                        // style={{
                        //   font: "normal normal medium 12px / 10px Montserrat",
                        //   color: "#777777",
                        // }}
                      >
                        {survey.status === "PUBLISH"
                          ? "PUBLISHED"
                          : survey.status === "DISABLE"
                          ? "DISABLED"
                          : survey.status}
                      </TableCell>
                      <TableCell>
                        {survey.status === "DRAFT" ||
                        survey.status === "DISABLE" ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={changeStatus(survey.id, "PUBLISH")}
                          >
                            Publish
                          </Button>
                        ) : survey.status === "PUBLISH" ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={changeStatus(survey.id, "DISABLE")}
                          >
                            Disable
                          </Button>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>
                        {survey.status === "DRAFT" ? (
                          <IconButton
                            onClick={props.displayDetails(
                              survey.id,
                              survey.status === "PUBLISH"
                            )}
                          >
                            <Edit />
                          </IconButton>
                        ) : survey.status === "PUBLISH" ? (
                          <IconButton
                            onClick={props.displayDetails(
                              survey.id,
                              survey.status === "PUBLISH"
                            )}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        ) : (
                          ""
                        )}
                        {survey.status === "DRAFT" ? (
                          <IconButton onClick={deleteSurvey(survey.id)}>
                            <Delete />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )
              : ""}
          </TableBody>
        </Table>
        {props.surveyList.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.totalElement}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          ""
        )}
      </ThemeProvider>
    </React.Fragment>
  );
}

export default SurveyList;
