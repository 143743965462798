// import axios from "axios";
import axios from "../api/axios";
import { BASE_URL } from "./AuthService";

class DoctorDataService {
  getDocInfo(doctorId) {
    return axios.get(`${BASE_URL}/doctors/doctorInfo/${doctorId}`);
  }

  updateDocInfo(doctor) {
    return axios.post(`${BASE_URL}/doctors/doctorInfo/${doctor.id}`, doctor);
  }

  searchDoctors(doctorInfo, clinicId) {
    //return axios.post(`${BASE_URL}/patients/searchPatients`, doctorInfo);
    return axios.post(
      `${BASE_URL}/clinics/${clinicId}/searchDoctors`,
      doctorInfo
    );
  }

  createDoctor(doctorInfo) {
    return axios.post(`${BASE_URL}/createDoctorAccount`, doctorInfo);
  }

  findPatients(doctorId) {
    return axios.get(`${BASE_URL}/doctors/${doctorId}/allPatients`);
  }

  findDoctorTreatment(clinicId, doctorId) {
    return axios.get(
      `${BASE_URL}/clinics/${clinicId}/doctors/${doctorId}/doctorTreatment`
    );
  }

  saveDoctorTreatmentConfig(doctorId, config) {
    return axios.post(`${BASE_URL}/doctors/${doctorId}/configCategory`, config);
  }

  findAllAvailableTime(doctorId) {
    return axios.get(`${BASE_URL}/doctors/${doctorId}/availTime`);
  }

  createAvailableTime(doctorId, availTime) {
    return axios.post(`${BASE_URL}/doctors/${doctorId}/availTime`, availTime);
  }

  deleteAvailableTime(doctorId, availTimeId) {
    return axios.delete(
      `${BASE_URL}/doctors/${doctorId}/availTime/${availTimeId}`
    );
  }

  saveAvailableTime(doctorId, availTime) {
    return axios.post(
      `${BASE_URL}/doctors/${doctorId}/updateAvailTime`,
      availTime
    );
  }

  deleteDoctor(clinicId, doctorId) {
    return axios.delete(
      `${BASE_URL}/clinics/${clinicId}/doctorlink/${doctorId}`
    );
  }

  findAllCategoriesOfDoctor(doctorId) {
    return axios.get(`${BASE_URL}/doctors/${doctorId}/category`);
  }

  linkCategory2Doctor(doctorId, categoryLink) {
    return axios.post(`${BASE_URL}/doctors/${doctorId}/category`, categoryLink);
  }

  deleteDoctorCategory(doctorId, categoryId) {
    return axios.delete(
      `${BASE_URL}/doctors/${doctorId}/category/${categoryId}`
    );
  }

  updateCategorySetting(doctorId, category) {
    return axios.put(
      `${BASE_URL}/doctors/${doctorId}/category/${category.categoryId}`,
      category
    );
  }

  findAppsByDoctors(doctorId, clinicId) {
    return axios.get(
      `${BASE_URL}/appointments/clinics/${clinicId}/doctor/${doctorId}`
    );
  }

  findSurveys(doctorId) {
    return axios.get(`${BASE_URL}/doctors/${doctorId}/surveys`);
  }

  saveSurvey(doctorId, survey) {
    return axios.post(`${BASE_URL}/doctors/${doctorId}/surveys`, survey);
  }

  removeDoctorSurvey(id) {
    return axios.delete(`${BASE_URL}/doctors/doctorSurveys/${id}`);
  }

  uploadDoctorPhoto(doctorId, photo) {
    return axios.post(`${BASE_URL}/doctors/${doctorId}/photo`, photo, {});
  }

  linkDoc2Clinic(clinicId, doctor) {
    return axios.post(`${BASE_URL}/clinics/${clinicId}/doctorlink`, doctor);
  }
}

export default new DoctorDataService();
