import React, { useState, useEffect } from "react";
//import moment from "moment";
import moment from "moment-timezone";
import {
  Stepper,
  Paper,
  Step,
  StepLabel,
  Button,
  makeStyles,
  Container,
  Typography,
  Divider,
  FormLabel,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Checkbox,
  CardActions,
  Avatar,
} from "@material-ui/core";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SmsIcon from "@material-ui/icons/Sms";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import PatientDataService from "../services/PatientDataService";
import DoctorDataService from "../services/DoctorDataService";
import SurveyDataService from "../services/SurveyDataService";
import AppointmentDataService from "../services/AppointmentDataService";
import ClinicDataService from "../services/ClinicDataService";
import AppQuestion from "./AppQuestion";
import "./PatienDetailStyle.css";
import { useAppContext } from "../services/authContext";

function getSteps() {
  return [
    "General Info",
    "Patient",
    "Survey",
    "Date and time",
    "Confirmation",
    "Additional Info",
  ];
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "40%",
  },
  button: {
    margin: theme.spacing(2),
  },
  tableCellTop: {
    verticalAlign: "top",
  },
}));

function NewApp(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [chosenDay, setChosenDay] = useState(moment());
  const steps = getSteps();
  const [startDate, setStartDate] = useState(moment());
  const [daysTitle, setDaysTitle] = useState([]);
  const [clinicInfo, setClinicInfo] = useState({ treatmentType: "1" });
  const [searchPatient, setSearchPatient] = useState({});
  const [patientList, setPatientList] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [availableApp, setAvailableApp] = useState([]);
  const [timeSlot, setTimeSlot] = useState({});
  const [survey, setSurvey] = useState({});
  const [questions, setQuestions] = useState([]);
  const [confirmDetails, setConfirmDetails] = useState({});
  const [answers, setAnswers] = useState(new Map());
  const [room, setRoom] = useState(0);
  const [comment, setComment] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [emailReminders, setEmailReminders] = useState([]);
  const [phoneReminders, setPhoneReminders] = useState([]);
  const [smsReminders, setSmsReminders] = useState([]);
  const [reminderType, setReminderType] = useState(0);
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderTime, setReminderTime] = useState(null);
  const [reminderConfirm, setReminderConfirm] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [hasAvailableApp, sethasAvailableApp] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(false);
  const { displayMessage, clinicTZs, clinicId } = useAppContext();
  const [errors, setErrors] = useState({});
  const [dateExp, setDateExp] = useState(null);
  const [dateBd, setDateBd] = useState(null);
  const [categories, setCategories] = useState([]);
  const [treatmentTypes, setTreatmentTypes] = useState([]);

  useEffect(() => {
    buildDaysTitle(chosenDay);
  }, []);

  const changeClinicInfo = (keyWord) => (event) => {
    setAvailableApp([]);
    if (keyWord !== "doctorId" && keyWord !== "categoryId") {
      setClinicInfo({ ...clinicInfo, [keyWord]: event.target.value });
    } else {
      if (keyWord === "doctorId") {
        setClinicInfo({
          ...clinicInfo,
          categoryId: null,
          doctorId: event.target.value,
          treatmentType: null,
        });
        DoctorDataService.findAllCategoriesOfDoctor(event.target.value).then(
          (response) => {
            let tempCategories = [];
            response.data.forEach((rawCategory) => {
              let description = JSON.parse(rawCategory.categoryDescription);
              tempCategories.push({
                id: rawCategory.categoryId,
                name: description.en,
              });
            });
            setCategories([...tempCategories]);
          },
          (error) => console.log(error)
        );
      }

      if (keyWord === "categoryId") {
        setClinicInfo({
          ...clinicInfo,
          categoryId: event.target.value,
          treatmentTypeId: null,
        });
        ClinicDataService.findCategoryTypes(event.target.value).then(
          (response) => {
            let tmpTypes = [];
            response.data.forEach((rawType) => {
              let description = JSON.parse(rawType.name);
              tmpTypes.push({
                id: rawType.id + "",
                name: description.en,
              });
            });
            setTreatmentTypes([...tmpTypes]);
          },
          (error) => console.log(error)
        );
      }
    }

    if (event.target.value) {
      if (keyWord === "doctorId") {
        setErrors({ ...errors, doctorId: null });
      }
      if (keyWord === "categoryId") {
        setErrors({ ...errors, categoryId: null });
      }
    }
  };

  const changeSearchPatient = (keyWord) => (event) => {
    setSearchPatient({ ...searchPatient, [keyWord]: event.target.value });
    validateDoctor();
  };

  const selectSlot = (slot) => (event) => {
    setTimeSlot(slot);
    setSelectedTimeSlot(true);
  };

  function selectPatientId(event) {
    setSelectedPatientId(parseInt(event.target.value));
    PatientDataService.findPatientById(event.target.value).then(
      (response) => setSearchPatient({ ...response.data }),
      (error) => console.log(error)
    );
  }

  function isEmpty(item) {
    return !item || item.length === 0;
  }

  function validateDoctor() {
    if (!clinicInfo.doctorId) {
      setErrors({ ...errors, doctorId: "Doctor cannot be empty" });
      return false;
    }
    return true;
  }

  function validateCategory() {
    if (!clinicInfo.treatmentType) {
      setErrors({ ...errors, treatmentType: "Type cannot be empty" });
      return false;
    }
    return true;
  }

  function validateAppointmentType() {
    if (!clinicInfo.categoryId) {
      setErrors({ ...errors, categoryId: "Category cannot be empty" });
      return false;
    }
    return true;
  }

  function validatePaientSearchParams() {
    if (
      isEmpty(searchPatient.mediCardNumber) &&
      isEmpty(searchPatient.firstName) &&
      isEmpty(searchPatient.lastName) &&
      isEmpty(searchPatient.dateOfBirth)
    ) {
      window.alert(
        "At least one of following 4 fileds is mandatory: medical card, first name, last name and date of birth."
      );
      return false;
    }
    return true;
  }

  function findPatient() {
    if (!validatePaientSearchParams()) {
      return;
    }
    PatientDataService.searchAllPatient(searchPatient).then(
      (response) => {
        setPatientList(response.data.content);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const changeTextAnswer = (questionId) => (event) => {
    let tempAnswers = new Map(answers);
    tempAnswers.set(questionId, event.target.value);
    setAnswers(new Map(tempAnswers));
  };

  const changeSingleAnswer = (questionId) => (event) => {
    console.log(questionId);
    let tempAnswers = new Map(answers);
    tempAnswers.set(questionId, parseInt(event.target.value));
    setAnswers(new Map(tempAnswers));
  };

  const changeMultiAnswer = (questionId, index) => (event) => {
    let tempAnswers = new Map(answers);
    tempAnswers.get(questionId)[index] = event.target.checked;
    setAnswers(new Map(tempAnswers));
  };

  function changeRoom(event) {
    setRoom(event.target.value);
  }

  function changeComment(event) {
    setComment(event.target.value);
  }

  function changedConfirmed(event) {
    setConfirmed(event.target.checked);
  }

  function buildDaysTitle(changedDay) {
    let temp = [];
    for (let i = 0; i < 7; i++) {
      temp.push({
        day: changedDay.clone().day(i).format("ddd"),
        date: changedDay.clone().day(i).format("D"),
      });
    }
    setDaysTitle(temp);
  }

  function nextWeek() {
    const targetDay = chosenDay.clone().add(7, "days");
    setChosenDay(targetDay);
    populateAvailApps(targetDay);
    buildDaysTitle(targetDay);
  }

  function previousWeek() {
    const targetDay = chosenDay.clone().subtract(7, "days");
    setChosenDay(targetDay);
    populateAvailApps(targetDay);
    buildDaysTitle(targetDay);
  }

  function handleStartDateChange(date) {
    setStartDate(date);
  }

  function handleBack() {
    if (activeStep === 5) {
      props.closeNewApp(timeSlot.date);
    } else {
      setActiveStep(activeStep - 1);
    }
  }

  function handleFileChange(event) {
    setUploadFile(event.target.files[0]);
  }

  function handleUploadFile() {
    const data = new FormData();
    data.append("file", uploadFile);
    PatientDataService.uploadFile(
      selectedPatientId,
      data
    ).then((response) => {});
  }

  function clearSearchPatient() {
    setSearchPatient({});
  }

  function handleNext() {
    if (5 === activeStep) {
      props.closeNewApp(timeSlot.date);
    }
    if (0 === activeStep) {
      if (!validateDoctor()) {
        return;
      }
      if (!validateCategory()) {
        return;
      }
      if (!validateAppointmentType()) {
        return;
      }
      clearSearchPatient();
    }
    if (1 === activeStep) {
      if (!selectedPatientId || selectedPatientId === 0) {
        window.alert("Please choose patient.");
        return;
      } else {
        SurveyDataService.findSurveyForApp({
          //clinicId: clinicId,
          //categoryId: clinicInfo.categoryId,
          treatmentId: parseInt(clinicInfo.treatmentType),
          doctorId: clinicInfo.doctorId,
          patientId: selectedPatientId,
        }).then(
          (response) => {
            if (response.data.surveyQuestions) {
              setSurvey(response.data);
              //              SurveyDataService.findSurveyById(response.data.surveyId).then(
              //                (response) => console.log(response),
              //                (error) => console.log(error)
              //             );
              let tempQuestions = [];
              let tempAnswers = new Map();

              response.data.surveyQuestions.forEach((question) => {
                let tempOptions = JSON.parse(question.options);
                if (response.data.patientSurveys) {
                  let patientAnswer = response.data.patientSurveys.find(
                    (e) => e.questionId === question.id
                  );
                  if (3 === question.type) {
                    let patientOptionAnswer = patientAnswer.answer
                      .replaceAll('"]', "")
                      .replaceAll('["', "")
                      .replaceAll('"', ",")
                      .split(",");
                    let tempOptionAnswers = [];
                    tempOptions.forEach((option) => {
                      tempOptionAnswers.push(
                        patientOptionAnswer.includes(option.fr) ||
                          patientOptionAnswer.includes(option.en)
                      );
                    });
                    tempAnswers.set(question.id, tempOptionAnswers);
                  }
                  if (1 === question.type) {
                    tempAnswers.set(
                      question.id,
                      patientAnswer.answer
                        .replaceAll('"]', "")
                        .replaceAll('["', "")
                    );
                  }
                  if (2 === question.type) {
                    let answer = patientAnswer.answer
                      .replaceAll('"]', "")
                      .replaceAll('["', "");
                    let answerId = tempOptions.findIndex(
                      (option) =>
                        option.en.includes(answer) || option.fr.includes(answer)
                    );
                    tempAnswers.set(question.id, answerId);
                  }
                } else {
                  if (3 === question.type) {
                    let tempOptionAnswers = [];
                    tempOptions.forEach((option) => {
                      tempOptionAnswers.push(false);
                    });
                    tempAnswers.set(question.id, tempOptionAnswers);
                  }
                  if (1 === question.type) {
                    tempAnswers.set(question.id, "");
                  }
                  if (2 === question.type) {
                    tempAnswers.set(question.id, 0);
                  }
                }

                let tempContext = JSON.parse(question.context);
                tempQuestions.push({
                  ...question,
                  context: tempContext.en,
                  context_fr: tempContext.fr,
                  options: tempOptions,
                });
              });
              setAnswers(new Map(tempAnswers));
              console.log(tempAnswers);
              setQuestions([...tempQuestions]);
              console.log(tempQuestions);
            } else {
              console.log("no suvery available");
              setSurvey({});
              setQuestions([]);
              setAnswers(new Map());
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
    if (2 === activeStep) {
      if (answers) {
        let isValid = true;
        answers.forEach((value, key) => {
          const questionItem = questions.find((x) => x.id === key);
          if (questionItem && questionItem.mandatory) {
            switch (questionItem.type) {
              case 1: // text
                if (value == null || value === "") isValid = false;
                break;
              case 2: // single
                if (value == null || Number.isNaN(value)) isValid = false;
                break;
              case 3: // multiple select
                const selected = value.find((x) => x === true);
                if (selected == null) isValid = false;
                break;
              case 4: // upload
                if (value == null) isValid = false;
                break;
              default:
                break;
            }
          }
        });
        if (!isValid) {
          window.alert("Mandatory question's answer is required.");
          return;
        }
      }
      populateAvailApps(startDate);
    }
    if (3 === activeStep) {
      setConfirmDetails({
        patient: patientList.find(
          (element) => element.patientId === selectedPatientId
        ),
        doctor: props.clinicDoctors.find(
          (element) => element.id === clinicInfo.doctorId
        ),
        category: categories.find(
          (element) => element.id === clinicInfo.categoryId
        ),
        type: treatmentTypes.find(
          (element) => element.id === clinicInfo.treatmentType
        ),
      });
      console.log(confirmDetails);
    }
    if (4 === activeStep) {
      const answersArray = [];
      /*answers.forEach((value, questionId) => {
        if (Array.isArray(value)) {
          answersArray.push({
            questionId: questionId,
            answer: value.join(","),
          });
        } else {
          answersArray.push({
            questionId: questionId,
            answer: value + "",
          });
        }
      });*/
      questions.forEach((question) => {
        if (question.type === 1) {
          answersArray.push({
            questionId: question.id,
            answer: '["' + answers.get(question.id) + '"]',
          });
        } else if (question.type === 2) {
          answersArray.push({
            questionId: question.id,
            answer: '["' + question.options[answers.get(question.id)].en + '"]',
          });
        } else if (question.type === 3) {
          const multiAnswersArray = [];
          answers.get(question.id).forEach((element, index) => {
            if (element) {
              multiAnswersArray.push(question.options[index].en);
            }
          });
          answersArray.push({
            questionId: question.id,
            answer: '["' + multiAnswersArray.join('","') + '"]',
          });
        }
      });
      console.log(answersArray);
      AppointmentDataService.saveNewApp({
        clinicId: clinicId,
        doctorId: clinicInfo.doctorId,
        doctorTreatmentId: timeSlot.doctorTreatmentId,
        patientId: selectedPatientId,
        appointmentDate: timeSlot.date,
        startTime: timeSlot.startTime,
        endTime: timeSlot.endTime,
        status: "1",
        lang: "en",
        surveyId: survey.surveyId ? survey.surveyId : "",
        patientSurveys: answersArray,
      }).then(
        (response) => {
          setAppointment(response.data);
          displayMessage("You have booked successfully.");
          console.log(response);
        },
        (error) => {
          setActiveStep(4);
          window.alert("Cannot save appointment due to server error");
          console.log(error);
        }
      );
      ClinicDataService.getDefaultReminders(clinicId).then(
        (response) => {
          const today = moment().format("YYYY-MM-DD");
          let emailArray = response.data.numberOfDaysInAdvanceForEmail.split(
            "-"
          );
          let emailDates = [];
          emailArray.forEach((num) => {
            const calDate = moment(timeSlot.date)
              .subtract(num, "days")
              .format("YYYY-MM-DD");
            let calDateTime = moment(
              calDate + " " + timeSlot.startTime,
              "YYYY-MM-DD hh:mm"
            );
            console.log(
              calDate + "-" + today + "=" + moment(calDate).isAfter(today)
            );
            console.log(calDateTime);
            if (moment(calDate).isAfter(today)) {
              emailDates.push({
                date: calDateTime,
                time: calDateTime,
                confirm: false,
              });
            }
          });
          emailDates.sort((a, b) => {
            return a.date.isBefore(b.date);
          });
          let phoneArray = response.data.numberOfDaysInAdvanceForPhone.split(
            "-"
          );
          let phoneDates = [];
          phoneArray.forEach((num) => {
            const calDate = moment(timeSlot.date)
              .subtract(num, "days")
              .format("YYYY-MM-DD");
            let calDateTime = moment(
              calDate + " " + timeSlot.startTime,
              "YYYY-MM-DD hh:mm"
            );
            console.log(
              calDate + "-" + today + "=" + moment(calDate).isAfter(today)
            );
            console.log(calDateTime);
            if (moment(calDate).isAfter(today)) {
              phoneDates.push({
                date: calDateTime,
                time: calDateTime,
                confirm: false,
              });
            }
          });
          phoneDates.sort((a, b) => {
            return a.date.isBefore(b.date);
          });
          let smsArray = response.data.numberOfDaysInAdvanceForTm.split("-");
          let smsDates = [];
          smsArray.forEach((num) => {
            const calDate = moment(timeSlot.date)
              .subtract(num, "days")
              .format("YYYY-MM-DD");
            let calDateTime = moment(
              calDate + " " + timeSlot.startTime,
              "YYYY-MM-DD hh:mm"
            );
            console.log(
              calDate + "-" + today + "=" + moment(calDate).isAfter(today)
            );
            console.log(calDateTime);
            if (moment(calDate).isAfter(today)) {
              smsDates.push({
                date: calDateTime,
                time: calDateTime,
                confirm: false,
              });
            }
          });
          smsDates.sort((a, b) => {
            return a.date.isBefore(b.date);
          });
          console.log(emailDates);
          setEmailReminders([...emailDates]);
          setPhoneReminders([...phoneDates]);
          setSmsReminders([...smsDates]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (5 === activeStep) {
      AppointmentDataService.saveRoom(appointment.id, room).then(
        (response) => console.log(response.data),
        (error) => console.log(error)
      );
      AppointmentDataService.savePrepareComment(appointment.id, comment).then(
        (response) => console.log(response.data),
        (error) => console.log(error)
      );
      const reminders = [];
      emailReminders.forEach((reminder) => {
        console.log(reminder.date);
        console.log(reminder.time);
        console.log(transfer2StrFormatWithTZ(reminder));
        reminders.push({
          type: "EMAIL",
          requireConfirm: reminder.confirm,
          reminderTime: [transfer2StrFormatWithTZ(reminder)],
        });
      });
      phoneReminders.forEach((reminder) => {
        reminders.push({
          type: "VOICE",
          requireConfirm: reminder.confirm,
          reminderTime: [transfer2StrFormatWithTZ(reminder)],
        });
      });
      smsReminders.forEach((reminder) => {
        reminders.push({
          type: "SMS",
          requireConfirm: reminder.confirm,
          reminderTime: [transfer2StrFormatWithTZ(reminder)],
        });
      });
      AppointmentDataService.saveReminders(reminders, appointment.id).then(
        (response) => {
          console.log(response.data);
          displayMessage("Thank you. Appointment reminders have been saved.");
        },
        (error) => console.log(error)
      );
    }
    setActiveStep(activeStep + 1);
  }

  function transfer2StrFormatWithTZ(reminder) {
    /*    let localDateTime = new Date(
      Date.parse(
        (typeof reminder.date === "string"
          ? reminder.date
          : reminder.date.format("YYYY-MM-DD")) +
          " " +
          reminder.time.format("HH:mm:ss")
      )
    );
    */
    let clinicDateTime = moment.tz(
      (typeof reminder.date === "string"
        ? reminder.date
        : reminder.date.format("YYYY-MM-DD")) +
        " " +
        reminder.time.format("HH:mm:ss"),
      clinicTZs.get(clinicId)
    );
    console.log(clinicDateTime);
    console.log(clinicDateTime.utc().format());
    let splittedArray = clinicDateTime.utc().format().split("T");
    console.log(splittedArray);
    return splittedArray[0] + " " + splittedArray[1].substring(0, 8);
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  function resetPage() {
    setSearchPatient({
      ...searchPatient,
      mediCardNumber: "",
      firstName: "",
      lastName: "",
      contactPhone: "",
      email: "",
      postcode: "",
      streetNumber: "",
      streetName: "",
      city: "",
      country: "",
      mediCardExp: "",
      dateOfBirth: "",
    });
    setDateExp(null);
    setDateBd(null);
  }

  function handleDateChangeExp(date) {
    setDateExp(date);
    setSearchPatient({
      ...searchPatient,
      mediCardExp: moment(date).format("YYYY-MM-DD"),
    });
  }

  function handleDateChangeBd(date) {
    setDateBd(date);
    setSearchPatient({
      ...searchPatient,
      dateOfBirth: moment(date).format("YYYY-MM-DD"),
    });
  }

  const populateAvailApps = (searchStartDate) => {
    AppointmentDataService.findAvailableApp(
      clinicId,
      clinicInfo.doctorId,
      clinicInfo.categoryId,
      clinicInfo.treatmentType,
      moment(searchStartDate).format("YYYY-MM-DD")
    ).then(
      (response) => {
        const apps = Object.entries(response.data);
        let count = 0;
        apps.map(
          (weekday) =>
            (count += weekday[1][clinicInfo.doctorId].timeSlots.length)
        );
        console.log(count);
        const isAppAvailable = count === 0 ? false : true;
        sethasAvailableApp(isAppAvailable);
        if (isAppAvailable) {
          setAvailableApp(apps);
          setChosenDay(moment(apps[0][0]));
          buildDaysTitle(moment(apps[0][0]));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const deletePhoneReminder = (index) => (event) => {
    // if (window.confirm("Do you really want to delete this reminder?")) {
    let tempReminders = [...phoneReminders];
    tempReminders.splice(index, 1);
    setPhoneReminders([...tempReminders]);
    // }
  };

  const deleteSmsReminder = (index) => (event) => {
    // if (window.confirm("Do you really want to delete this reminder?")) {
    let tempReminders = [...smsReminders];
    tempReminders.splice(index, 1);
    setSmsReminders([...tempReminders]);
    // }
  };

  const deleteEmailReminder = (index) => (event) => {
    // if (window.confirm("Do you really want to delete this reminder?")) {
    let tempReminders = [...emailReminders];
    tempReminders.splice(index, 1);
    setEmailReminders([...tempReminders]);
    // }
  };

  const changeReminder = (event) => {
    setReminderType(event.target.value);
  };

  const changeReminderDate = (date) => {
    setReminderDate(date);
  };

  const changeReminderTime = (time) => {
    setReminderTime(time);
  };

  const changeReminderConfirm = (event) => {
    setReminderConfirm(event.target.checked);
  };

  const changePhoneReminderConfirm = (selectedIndex) => (event) => {
    let tempReminders = [...phoneReminders];
    tempReminders.forEach((reminder, index) => {
      if (selectedIndex === index) {
        reminder.confirm = event.target.checked;
      }
    });
    setPhoneReminders([...tempReminders]);
  };

  const changeEmailReminderConfirm = (selectedIndex) => (event) => {
    let tempReminders = [...emailReminders];
    tempReminders.forEach((reminder, index) => {
      if (selectedIndex === index) {
        reminder.confirm = event.target.checked;
      }
    });
    setEmailReminders([...tempReminders]);
  };

  const changeSmsReminderConfirm = (selectedIndex) => (event) => {
    let tempReminders = [...smsReminders];
    tempReminders.forEach((reminder, index) => {
      if (selectedIndex === index) {
        reminder.confirm = event.target.checked;
      }
    });
    setSmsReminders([...tempReminders]);
  };

  const addReminder = (event) => {
    let tempReminders = [];
    console.log(reminderDate + " " + reminderTime);
    if (!reminderDate || !reminderTime) {
      window.alert("Invalid date OR invlid time.");
      return;
    }
    let dateMoment = moment(reminderDate);
    let timeMoment = moment(reminderTime);
    console.log(dateMoment + ":" + timeMoment);
    switch (reminderType) {
      case 0:
        tempReminders = [...phoneReminders];
        tempReminders.push({
          date: dateMoment,
          time: timeMoment,
          confirm: reminderConfirm,
        });
        setPhoneReminders([...tempReminders]);
        // displayMessage("Phone reminder set successfully.");
        break;
      case 1:
        tempReminders = [...emailReminders];
        tempReminders.push({
          date: dateMoment,
          time: timeMoment,
          confirm: reminderConfirm,
        });
        setEmailReminders([...tempReminders]);
        // displayMessage("Email reminder set successfully.");
        break;
      case 2:
        tempReminders = [...smsReminders];
        tempReminders.push({
          date: dateMoment,
          time: timeMoment,
          confirm: reminderConfirm,
        });
        setSmsReminders([...tempReminders]);
        // displayMessage("Sms reminder set successfully.");
        break;
      default:
        console.log("no reminder is added!!!");
    }
  };

  return (
    // Style comes fronm src\components\PatienDetailStyle.css line:269
    <React.Fragment>
      <Typography align="right">
        <IconButton color="primary" onClick={props.closeNewApp}>
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </Typography>
      <Stepper activeStep={activeStep} className="newApp-stepper">
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Paper className="newApp-paper">
        <form className="newApp-form">
          <Container
            style={{ display: activeStep === 0 ? "block" : "none" }}
            className="newapp-area step-zero"
          >
            <Typography variant="h5" className="step-title">
              <span className="step-title-number">{activeStep + 1}&nbsp;</span>
              <span className="step-title-desc">{steps[activeStep]}</span>
            </Typography>
            <Divider />
            <FormControl className={"group-item " + classes.formControl}>
              <Typography className="group-item-title" variant="subtitle2">
                Doctor
              </Typography>
              <Select
                className="group-item-input"
                onChange={changeClinicInfo("doctorId")}
                value={clinicInfo.doctorId ? clinicInfo.doctorId : ""}
                error={errors.doctorId ? true : false}
              >
                {props.clinicDoctors.map((doctor) => (
                  <MenuItem value={doctor.id}>
                    {(doctor.firstName ? doctor.firstName : "") +
                      " " +
                      (doctor.middleName ? doctor.middleName : "") +
                      " " +
                      (doctor.lastName ? doctor.lastName : "")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {errors.doctorId && (
              <Typography
                variant="subtitle1"
                style={{ color: "red", paddingLeft: "16px" }}
              >
                {errors.doctorId}
              </Typography>
            )}
            <FormControl className={"group-item " + classes.formControl}>
              {!categories || categories.length === 0 ? (
                <Typography variant="h8" style={{ color: "#F75010" }}>
                  No category assigned
                </Typography>
              ) : (
                ""
              )}
              <Typography className="group-item-title" variant="subtitle2">
                Category
              </Typography>
              <Select
                error={errors.categoryId ? true : false}
                onChange={changeClinicInfo("categoryId")}
                value={clinicInfo.categoryId ? clinicInfo.categoryId : ""}
              >
                {categories.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {errors.categoryId && (
              <Typography
                variant="subtitle1"
                style={{ color: "red", paddingLeft: "16px" }}
              >
                {errors.categoryId}
              </Typography>
            )}
            <FormControl className="group-item">
              <FormLabel className="group-item-title" component="legend">
                Type
              </FormLabel>
              <RadioGroup
                row
                value={clinicInfo.treatmentType}
                onChange={changeClinicInfo("treatmentType")}
              >
                {treatmentTypes.map((type) => (
                  <FormControlLabel
                    value={type.id}
                    control={<Radio color="primary" />}
                    label={type.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <br />
            {errors.treatmentType && (
              <Typography
                variant="subtitle1"
                style={{ color: "red", paddingLeft: "16px" }}
              >
                {errors.treatmentType}
              </Typography>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                className="group-item"
                id="date-picker-dialog"
                label="Start date"
                format="yyyy-MM-dd"
                minDate={new Date()}
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Container>
          <Container
            style={{ display: activeStep === 1 ? "block" : "none" }}
            className="newapp-area step-first"
          >
            <Typography variant="h5" className="step-title">
              <span className="step-title-number">{activeStep + 1}&nbsp;</span>
              <span className="step-title-desc">{steps[activeStep]}</span>
            </Typography>
            <Divider />
            <Grid container md={12} spacing={3} style={{ marginTop: 50 }}>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">
                    Medical card number
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={
                      searchPatient.mediCardNumber
                        ? searchPatient.mediCardNumber
                        : ""
                    }
                    onChange={changeSearchPatient("mediCardNumber")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">Expiration</FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      minDate={new Date()}
                      value={dateExp}
                      onChange={handleDateChangeExp}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">First name</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={
                      searchPatient.firstName ? searchPatient.firstName : ""
                    }
                    onChange={changeSearchPatient("firstName")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">Last name</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={searchPatient.lastName ? searchPatient.lastName : ""}
                    onChange={changeSearchPatient("lastName")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">Phone</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={
                      searchPatient.contactPhone
                        ? searchPatient.contactPhone
                        : ""
                    }
                    onChange={changeSearchPatient("contactPhone")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">
                    Date of birth
                  </FormLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      format="yyyy-MM-dd"
                      maxDate={new Date()}
                      value={dateBd}
                      onChange={handleDateChangeBd}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">Email</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={searchPatient.email ? searchPatient.email : ""}
                    onChange={changeSearchPatient("email")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">
                    PostalCode / Zip
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={searchPatient.postcode ? searchPatient.postcode : ""}
                    onChange={changeSearchPatient("postcode")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">
                    Street Number
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={
                      searchPatient.streetNumber
                        ? searchPatient.streetNumber
                        : ""
                    }
                    onChange={changeSearchPatient("streetNumber")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">
                    Street Name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={
                      searchPatient.streetName ? searchPatient.streetName : ""
                    }
                    onChange={changeSearchPatient("streetName")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">City</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={searchPatient.city ? searchPatient.city : ""}
                    onChange={changeSearchPatient("city")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth className="group-item">
                  <FormLabel className="group-item-title">Country</FormLabel>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={searchPatient.country ? searchPatient.country : ""}
                    onChange={changeSearchPatient("country")}
                  />
                </FormControl>
              </Grid>
              <Grid item md={10}></Grid>
            </Grid>
            <Container style={{ display: "flex", justifyContent: "right" }}>
              <Button
                variant="contained"
                onClick={findPatient}
                style={{
                  marginTop: 30,
                  color: "#000000",
                  backgroundColor: "#f0f8ff",
                  width: "72px",
                  height: "30px",
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                onClick={resetPage}
                style={{
                  marginTop: 30,
                  marginLeft: 20,
                  color: "#000000",
                  backgroundColor: "#fff8dc",
                  width: "72px",
                  height: "30px",
                }}
              >
                Reset
              </Button>
            </Container>
            <Divider
              style={{ marginTop: 60, marginRight: 10, marginBottom: 50 }}
            />
            <Container>
              <RadioGroup value={selectedPatientId} onChange={selectPatientId}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>MCN</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Date of birth</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientList.map((candidate) => (
                      <TableRow key={candidate.patientId}>
                        <TableCell>
                          <FormControlLabel
                            value={candidate.patientId}
                            control={<Radio />}
                            // label={candidate.patientId}
                          />
                        </TableCell>
                        <TableCell>
                          {candidate.account.firstName === undefined
                            ? ""
                            : candidate.account.firstName}
                          {candidate.account.middleName === undefined
                            ? ""
                            : " " + candidate.account.middleName}
                          {candidate.account.lastName === undefined
                            ? ""
                            : " " + candidate.account.lastName}
                        </TableCell>
                        <TableCell>{candidate.mediCardNumber}</TableCell>
                        <TableCell>{candidate.account.phoneNumber}</TableCell>
                        <TableCell>{candidate.account.dateOfBirth}</TableCell>
                        <TableCell>{candidate.account.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </RadioGroup>
            </Container>
          </Container>
          <Container
            style={{ display: activeStep === 2 ? "block" : "none" }}
            className="newapp-area step-third"
          >
            <Typography variant="h5" className="step-title">
              <span className="step-title-number">{activeStep + 1}&nbsp;</span>
              <span className="step-title-desc">{steps[activeStep]}</span>
            </Typography>
            <Divider />
            <Card variant="outlined" style={{ borderColor: "#405DB6" }}>
              {survey.survey ? (
                <React.Fragment>
                  <Grid container style={{ backgroundColor: "#e1e5f4" }}>
                    <Grid item md={7}>
                      <Typography
                        style={{ margin: "0 25px", color: "#bec1c9" }}
                      >
                        Title{" "}
                      </Typography>
                      <Typography
                        style={{
                          margin: "0 25px",
                          color: "#405DB6",
                          fontWeight: "bold",
                        }}
                      >
                        {survey.survey ? survey.survey.title : ""}
                      </Typography>
                    </Grid>
                    <Grid item md={5}>
                      <Typography style={{ color: "#bec1c9" }}>ID </Typography>
                      <Typography style={{ color: "#405DB6" }}>
                        {survey.surveyId}
                      </Typography>
                    </Grid>
                  </Grid>
                  <AppQuestion
                    questions={questions}
                    answers={answers}
                    changeSingleAnswer={changeSingleAnswer}
                    changeTextAnswer={changeTextAnswer}
                    changeMultiAnswer={changeMultiAnswer}
                    handleFileChange={handleFileChange}
                    handleUploadFile={handleUploadFile}
                    disabled={false}
                  />
                </React.Fragment>
              ) : (
                "There is no survey required"
              )}
            </Card>
          </Container>
          <Container
            style={{ display: activeStep === 3 ? "block" : "none" }}
            className="newapp-area step-second"
          >
            <Typography variant="h5" className="step-title">
              <span className="step-title-number">{activeStep + 1}&nbsp;</span>
              <span className="step-title-desc">{steps[activeStep]}</span>
            </Typography>
            <Divider />
            <Grid container spacing={10} style={{ margin: 5 }}>
              <Grid item md={2} className="recommended-left">
                <Button variant="outlined" color="priamry">
                  Recommended
                </Button>
                <div className="sign-area">
                  <div className="sign-item">
                    <span className="sign-icon recommen-color"></span>
                    <span className="sign-text recommen-color">
                      Recommended
                    </span>
                  </div>
                  <div className="sign-item">
                    <span className="sign-icon regular-color"></span>
                    <span className="sign-text regular-color">Regular</span>
                  </div>
                  <div className="sign-item">
                    <span className="sign-icon overlap-color"></span>
                    <span className="sign-text overlap-color">Overlap</span>
                  </div>
                </div>
              </Grid>
              <Grid item md={10}>
                <Card>
                  <CardHeader
                    className="date-title"
                    title={moment(chosenDay).format("MMMM YYYY")}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item md={1}>
                        <IconButton
                          onClick={previousWeek}
                          disabled={moment(chosenDay).isBefore(moment())}
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={10}>
                        <Table className="appointment-table">
                          <TableHead className="appointment-table-head">
                            <TableRow>
                              {daysTitle.map((weekday) => (
                                <TableCell
                                  style={{ minWidth: 110 }}
                                  className="table-head-th"
                                >
                                  <TextField
                                    className="table-head-date"
                                    label={weekday.day}
                                    value={weekday.date}
                                    disabled
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody className="appointment-table-body">
                            <TableRow>
                              {hasAvailableApp ? (
                                availableApp.map((weekday, index) => (
                                  <TableCell
                                    key={index}
                                    className={classes.tableCellTop}
                                    align="center"
                                  >
                                    {weekday[1][
                                      clinicInfo.doctorId
                                    ].timeSlots.map((slot) => (
                                      <Button
                                        // className="appointment-table-btn"
                                        className={
                                          "appointment-table-btn " +
                                          (slot.type === 1
                                            ? "recommen-color"
                                            : "") +
                                          (slot.type === 2
                                            ? "regular-color"
                                            : "") +
                                          (slot.type === 3
                                            ? "overlap-color"
                                            : "")
                                        }
                                        variant="outlined"
                                        onClick={selectSlot(slot)}
                                        color={
                                          slot.date === timeSlot.date &&
                                          slot.startTime === timeSlot.startTime
                                            ? "primary"
                                            : "default"
                                        }
                                      >
                                        {slot.startTime}
                                      </Button>
                                    ))}
                                  </TableCell>
                                ))
                              ) : (
                                <TableCell colSpan="6">
                                  There is no available appointment for this
                                  doctor in the current selected week.
                                </TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item md={1}>
                        <IconButton onClick={nextWeek}>
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container
            style={{ display: activeStep === 4 ? "block" : "none" }}
            className="newapp-area step-forth"
          >
            <Typography variant="h5" className="step-title">
              <span className="step-title-number">{activeStep + 1}&nbsp;</span>
              <span className="step-title-desc">{steps[activeStep]}</span>
            </Typography>
            <Divider />
            <Paper variant="outlined" className="forth-paper">
              <Typography
                variant="subtitle1"
                align="center"
                className="paper-block-title"
              >
                {/* {new Date(timeSlot.date).toDateString()} */}
                {moment(timeSlot.date).format("dddd, MMMM Do YYYY")}
              </Typography>
              <Grid container spacing={0}>
                <Grid item md={3} className="time-area">
                  <Typography
                    variant="h6"
                    align="center"
                    className="time-circle"
                  >
                    <div className="circle-text">{timeSlot.startTime}</div>
                  </Typography>
                </Grid>
                <Grid item md={8} className="paper-block-card">
                  <Grid container spacing={3}>
                    <Grid item md={2} className="card-image-area">
                      <Avatar
                        className="card-image"
                        src={
                          confirmDetails.doctor
                            ? confirmDetails.doctor.photoUrl
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={10} className="card-content doc-desc">
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <Typography variant="subtitle2">
                                Doctor
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                Dr.{" "}
                                {confirmDetails.doctor
                                  ? confirmDetails.doctor.firstName +
                                    " " +
                                    //    confirmDetails.doctor.middleName +
                                    " " +
                                    confirmDetails.doctor.lastName
                                  : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="subtitle2">
                                Category
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {confirmDetails.category
                                  ? confirmDetails.category.name
                                  : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="subtitle2">Type</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {confirmDetails.type
                                  ? confirmDetails.type.name
                                  : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item md={2} className="card-image-area">
                      <Avatar
                        className="card-image patient-image"
                        src={
                          confirmDetails.patient
                            ? confirmDetails.patient.photoUrl
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={10} className="card-content doc-desc">
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ borderBottom: "none" }}>
                              <Typography variant="subtitle2">
                                Patient
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {confirmDetails.patient
                                  ? confirmDetails.patient.account.firstName +
                                    " " +
                                    (confirmDetails.patient.account.middleName
                                      ? confirmDetails.patient.account
                                          .middleName + " "
                                      : "") +
                                    confirmDetails.patient.account.lastName
                                  : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant="subtitle2">
                                Medical card number
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {confirmDetails.patient
                                  ? confirmDetails.patient.mediCardNumber
                                  : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <FormControlLabel
                className="checkbox-area"
                control={
                  <Checkbox
                    name="confirmed"
                    color="primary"
                    checked={confirmed}
                    onChange={changedConfirmed}
                  />
                }
                label="I accept terms and conditions"
                style={{ marginTop: 10 }}
              />
            </Paper>
          </Container>
          <Container
            style={{ display: activeStep === 5 ? "block" : "none" }}
            className="newapp-area step-fifth"
          >
            <Typography variant="h5" className="step-title">
              <span className="step-title-number">{activeStep + 1}&nbsp;</span>
              <span className="step-title-desc">{steps[activeStep]}</span>
            </Typography>
            <Divider />
            <Paper variant="outlined" className="fifth-paper">
              <Container>
                <Grid
                  container
                  style={{ margin: 5 }}
                  className="fifth-paper-section"
                >
                  <Grid className="left-section">
                    <Card className="fifth-paper-card">
                      <CardHeader
                        title="Preparation Comment"
                        className="card-title"
                      />
                      <CardContent>
                        <TextField
                          className="card-textarea"
                          id="outlined-multiline-static"
                          multiline
                          fullWidth
                          rows={4}
                          value={comment}
                          onChange={changeComment}
                          variant="outlined"
                        />
                      </CardContent>
                    </Card>
                    <Card
                      style={{ marginTop: 30 }}
                      className="fifth-paper-card"
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item md={5}>
                            <Typography variant="subtitle1" align="center">
                              Visit Room
                            </Typography>
                          </Grid>
                          <Grid item md={7}>
                            <TextField
                              value={room}
                              variant="outlined"
                              onChange={changeRoom}
                              className="card-input"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid className="right-section">
                    <Card
                      style={{ marginRight: 30 }}
                      className="fifth-paper-card"
                    >
                      <CardHeader title="Reminder" className="card-title" />
                      <CardContent>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>
                                {/* <TableCell>Need Confirm</TableCell> */}
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {phoneReminders
                                .sort((a, b) => a.date - b.date)
                                .map((reminder, index) => (
                                  <TableRow index={index}>
                                    <TableCell className="icon-name">
                                      <PhoneInTalkIcon />
                                      <span>Phone</span>
                                    </TableCell>
                                    <TableCell className="input-area">
                                      <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="yyyy-MM-dd"
                                        value={reminder.date}
                                        disabled
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell className="input-area">
                                      <KeyboardTimePicker
                                        margin="normal"
                                        id="time-picker"
                                        value={reminder.time}
                                        disabled
                                        KeyboardButtonProps={{
                                          "aria-label": "change time",
                                        }}
                                        keyboardIcon={
                                          <AccessTimeRoundedIcon></AccessTimeRoundedIcon>
                                        }
                                      />
                                    </TableCell>
                                    {/* <TableCell>
                                    <Switch
                                      color="primary"
                                      name="phoneConfirm"
                                      checked={reminder.confirm}
                                      disabled
                                      onChange={changePhoneReminderConfirm(
                                        index
                                      )}
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell> */}
                                    <TableCell>
                                      <IconButton
                                        onClick={deletePhoneReminder(index)}
                                      >
                                        <DeleteForeverOutlinedIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {emailReminders
                                .sort((a, b) => a.date - b.date)
                                .map((reminder, index) => (
                                  <TableRow index={index}>
                                    <TableCell className="icon-name">
                                      <MailOutlineIcon />
                                      <span>Email</span>
                                    </TableCell>
                                    <TableCell className="input-area">
                                      <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="yyyy-MM-dd"
                                        value={reminder.date}
                                        disabled
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell className="input-area">
                                      <KeyboardTimePicker
                                        margin="normal"
                                        id="time-picker"
                                        value={reminder.time}
                                        disabled
                                        KeyboardButtonProps={{
                                          "aria-label": "change time",
                                        }}
                                        keyboardIcon={
                                          <AccessTimeRoundedIcon></AccessTimeRoundedIcon>
                                        }
                                      />
                                    </TableCell>
                                    {/* <TableCell>
                                    <Switch
                                      color="primary"
                                      name="phoneConfirm"
                                      checked={reminder.confirm}
                                      disabled
                                      onChange={changeEmailReminderConfirm(
                                        index
                                      )}
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell> */}
                                    <TableCell>
                                      <IconButton
                                        onClick={deleteEmailReminder(index)}
                                      >
                                        <DeleteForeverOutlinedIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              {smsReminders
                                .sort((a, b) => a.date - b.date)
                                .map((reminder, index) => (
                                  <TableRow index={index}>
                                    <TableCell className="icon-name">
                                      <SmsIcon />
                                      <span>SMS</span>
                                    </TableCell>
                                    <TableCell className="input-area">
                                      <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="yyyy-MM-dd"
                                        value={reminder.date}
                                        disabled
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell className="input-area">
                                      <KeyboardTimePicker
                                        margin="normal"
                                        id="time-picker"
                                        value={reminder.time}
                                        disabled
                                        KeyboardButtonProps={{
                                          "aria-label": "change time",
                                        }}
                                        keyboardIcon={
                                          <AccessTimeRoundedIcon></AccessTimeRoundedIcon>
                                        }
                                      />
                                    </TableCell>
                                    {/* <TableCell>
                                    <Switch
                                      color="primary"
                                      name="phoneConfirm"
                                      checked={reminder.confirm}
                                      disabled
                                      onChange={changeSmsReminderConfirm(index)}
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell> */}
                                    <TableCell>
                                      <IconButton
                                        onClick={deleteSmsReminder(index)}
                                      >
                                        <DeleteForeverOutlinedIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </MuiPickersUtilsProvider>
                      </CardContent>
                      <CardActions className="actions-section">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Table className="create-area">
                            <TableBody>
                              <TableRow>
                                <TableCell className="input-area add-area type-select">
                                  <FormControl>
                                    <Select
                                      className="selected-item"
                                      value={reminderType}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      onChange={changeReminder}
                                    >
                                      <MenuItem value={0} className="icon-item">
                                        <PhoneInTalkIcon />
                                        <span>Phone</span>
                                      </MenuItem>
                                      <MenuItem value={1} className="icon-item">
                                        <MailOutlineIcon />
                                        <span>Email</span>
                                      </MenuItem>
                                      <MenuItem value={2} className="icon-item">
                                        <SmsIcon />
                                        <span>SMS</span>
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell className="input-area add-area">
                                  <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="yyyy-MM-dd"
                                    value={reminderDate}
                                    onChange={changeReminderDate}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </TableCell>
                                <TableCell className="input-area add-area">
                                  <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    value={reminderTime}
                                    onChange={changeReminderTime}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                    keyboardIcon={
                                      <AccessTimeRoundedIcon></AccessTimeRoundedIcon>
                                    }
                                  />
                                </TableCell>
                                {/* <TableCell>
                                  <Switch
                                    color="primary"
                                    name="confirm"
                                    checked={reminderConfirm}
                                    onChange={changeReminderConfirm}
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                  />
                                </TableCell> */}
                                <TableCell className="add-btn">
                                  <Button
                                    variant="outlined"
                                    onClick={addReminder}
                                  >
                                    Add
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </MuiPickersUtilsProvider>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Container>
          <Container className="btnGroup-container">
            <Button
              variant="contained"
              color="primary"
              onClick={handleBack}
              className={classes.button}
              disabled={activeStep === 0 ? true : false}
              style={{ display: activeStep === 0 ? "none" : "block" }}
            >
              {activeStep === 5 ? "Skip" : "Previous"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={"secondBtn " + classes.button}
              disabled={
                activeStep === 1
                  ? !patientList || patientList.length === 0
                  : activeStep === 3
                  ? !hasAvailableApp || !selectedTimeSlot
                  : activeStep === 4
                  ? !confirmed
                  : false
              }
            >
              {activeStep === steps.length - 1
                ? "Save"
                : activeStep === 4
                ? "Confirm"
                : "Next"}
              <span
                className={activeStep !== 4 ? "leftArrow" : "leftArrow-hide"}
              >
                <ArrowRightAltIcon />
              </span>
            </Button>
          </Container>
        </form>
      </Paper>
    </React.Fragment>
  );
}

export default NewApp;
